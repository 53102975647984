import type { BuilderComponent } from "@builder.io/react";
import { Cookie } from "../Cookie";

enum keysWithCNames {
  promoCode = "PROMOCODE",
  userIntent = "userIntent",
  competitorUserIntent = "competitorUserIntent",
  isPartner = "IS_PARTNER",
}

export default function onBuilderDropCookie(
  content: Parameters<BuilderComponent["onContentLoaded"]>[1]
) {
  const handleSetCookie = (
    cname: string,
    cvalue: string | boolean,
    domain?: string | null,
    sameSite?: string | null
  ) => {
    sameSite
      ? Cookie.setCookie(cname, cvalue, 365, false, domain, sameSite)
      : Cookie.setCookie(cname, cvalue, 365);
  };

  const deleteCookie = (cookieName: string) => {
    Cookie.deleteCookieFromBaseDomain(cookieName);
  };

  if (content?.data) {
    Object.entries(keysWithCNames).forEach(([key, cname]) => {
      const userIntent = key === "userIntent";

      if (content?.data[key]) {
        deleteCookie(cname);
        userIntent
          ? handleSetCookie(
              cname,
              content?.data[key],
              ".simplepractice.com",
              "none"
            )
          : handleSetCookie(cname, content?.data[key]);
      }
    });
  }
}
