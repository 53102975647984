import * as React from "react";
import { HeadProps, PageProps, graphql } from "gatsby";

import { BuilderComponent } from "@builder.io/react";
import { getBuilderFaqs } from "@utils/getBuilderFaqs";
import { handleContentLoaded } from "@utils/builderContentLoaded";
import { RegisterComponentLibrary } from "@utils/RegisterComponentLibrary";
import FAQSchema from "@components/FAQSchema";
import MainLayout from "@layouts/main";
import SEOMeta from "@components/SEOMeta";
import withCalculatorProvider from "@components/HOC/withCalculatorProvider";

import "@builder.io/widgets";
import { enableSmoothScroll } from "@utils/enableSmoothScroll";

import "../../libs/builder.setting";
import { RegisterHomepageLegacy } from "@components/BuilderWrapperComponents/HomepageLegacy";
import { Cookie } from "@utils/Cookie";
import { usePageModelData } from "../../hooks/usePageModelData";

type DataProps = {
  allBuilderModels: Queries.builder;
};

const StaticPageFullNav: React.FC<PageProps<DataProps>> = ({
  data,
  location,
}) => {
  const content = usePageModelData<
    Queries.builder_StaticPageFullNav["content"]
  >({
    model: "static-page-full-nav",
    urlPath: location.pathname,
    userAttr: {
      customer: Boolean(Cookie.getCookie("sp_last_access")),
    },
  });

  const isScrollable = content?.data?.useScroll;
  enableSmoothScroll(isScrollable);

  return (
    <MainLayout preventRerender pathname={location.pathname}>
      {content && (
        <BuilderComponent
          content={content}
          contentLoaded={handleContentLoaded}
          model="static-page-full-nav"
        />
      )}
    </MainLayout>
  );
};

RegisterComponentLibrary();
RegisterHomepageLegacy();

export default withCalculatorProvider(StaticPageFullNav);

export const Head: React.FC<HeadProps<DataProps>> = ({ data }) => {
  const {
    allBuilderModels: { oneStaticPageFullNav },
  } = data;
  const seoData = oneStaticPageFullNav?.content
    ?.data as Queries.builder_PageFullNavData;

  const content = oneStaticPageFullNav?.content;
  const faqs = getBuilderFaqs(content);

  return (
    <>
      <SEOMeta
        index={seoData?.index!}
        title={seoData?.title!}
        description={seoData?.description!}
      />

      {faqs && <FAQSchema faqsArray={faqs} />}

      {seoData?.json && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              ...seoData.json,
            }),
          }}
        />
      )}
    </>
  );
};

export const query = graphql`
  query ($path: String!) {
    allBuilderModels {
      oneStaticPageFullNav(
        target: { urlPath: $path }
        options: { cachebust: true, noTraverse: false, includeRefs: true }
      ) {
        content
      }
    }
  }
`;
