import type { BuilderComponent } from "@builder.io/react";
import { fireMxpEvent } from "./mixpanelEvents";
import { segmentTrack } from "@utils/segment/segment-track";

type ExperimentDataType = {
  experimentName: string;
  page: string;
  variation: string;
};

const trackExperiment = (expData: ExperimentDataType) => {
  if (typeof window !== "undefined") {
    segmentTrack("Experiment Started", {
      url: window.location.pathname,
      initiator: "User",
      object: "Experiment",
      action: "Started",
      source_application: "Marketing",
      experiment_name: expData.experimentName,
      variant: expData.variation,
    });
  }
};

export default function onBuilderExperimentStarted(
  content: Parameters<BuilderComponent["onContentLoaded"]>[1]
) {
  if (typeof content !== "object") return;

  const isExperiment = "data" in content && content.data!.useExperiment;

  if (isExperiment) {
    const { data } = content;

    const expData = {
      experimentName: data!.experimentName,
      page: data?.url || window.location.pathname,
      variation: data!.variantName,
    };

    fireMxpEvent("user: experiment started", expData);
    trackExperiment(expData);
  }
}
